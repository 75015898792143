<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import test from "@/app.config.json";
  import axios from "axios";
  import CKEditor from "@ckeditor/ckeditor5-vue";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  export default {
    page: {title: "EINSTUDIO-Library",meta: [{name: "description"}]},
    components: {
      Layout,PageHeader,ckeditor: CKEditor.component,vueDropzone: vue2Dropzone,
    },data() {
      return {
        title: "LIBRARY DETAILS",
        items: [{text: "Home",href:"/"}, {text: "Library",active: true},{text: ""},{text: ""}],
        baseUrl:test.baseUrl,fileUrl:test.fileUrl,user:[],libraryType:[],uploadedFiles: [],fileindex:0,fileArray:[],
        editor: ClassicEditor,editorData:"",
        editorConfig: {
          toolbar: { items: ['bold', 'italic','link','undo', 'redo','BulletedList', 'NumberedList', 'Blockquote'] }
        }, 
        categoryName:"",subCategoryName:"",subCategoryId:"",modalTitle:"",
        dropzoneOptions: {
          url: "https://httpbin.org/post",
          thumbnailWidth: 200,
          maxFilesize: 25,
          timeout: 180000,
          parallelUploads: 100,
          addRemoveLinks: true,
          acceptedFiles: ".img,.jpg,.png,.gif,.pptx,.pdf,.xlsx,.docx",
          accept: this.checkDupl
        }
      };
      },created:function(){
        this.user = JSON.parse(localStorage.getItem('user'));
        this.categoryName=this.$route.params.name;
        this.loadData(this.categoryName);
      },beforeRouteUpdate (to, from, next) {
        this.loadData(to.params.name);
        next();
      },methods: {
          loadData(name){
          let loader=this.$loading.show();
          this.title= name;
          this.items[2]=name;
          this.subCategoryName=name;
          axios.get(this.baseUrl+"/Library/LibraryType/GetAll?key="+name,{
            headers:{Authorization: `Bearer ${this.user.jwt}`}
          }).then((response)=>{
              this.libraryType= response.data;
              loader.hide();
          }).catch((error)=>{
           const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
          })
        },addPostData(){


          let loader = this.$loading.show();
          var formToPost = new FormData(document.getElementById("add-post-form"));
          formToPost.append("explanation",this.editorData)
          formToPost.append("categoeyName",this.modalTitle)

          axios.post(`${this.baseUrl}/Library/LibraryType/Add`, formToPost, {
            headers: {Authorization: `Bearer ${this.user.jwt}`},})
          .then((response) => 
          {
            loader.hide();
            this.$swal("",response.data.message, "success");
            this.loadData(this.modalTitle);
            this.hideModal('modal-library');

          }).catch((error) => 
          {
            loader.hide();
            var html="";
            if(error.response.data.length>0)
            {
              for (let index = 0; index < error.response.data.length; index++) 
              {
                html +="<small>"+ error.response.data[index].message+"</small><hr>";
              }
            }
            else
            {
              html ="<p class='small'>"+ error.response.data.message+"</p>";
            }
            this.$swal("",html,"error");
          });
        },addData(){
          let loader = this.$loading.show();
          var formToPost = new FormData(document.getElementById("add-file-form"));

          formToPost.append("userId",this.user.id)
          formToPost.append("isInGroup",false)
          formToPost.append("basePath",this.categoryName)
          formToPost.append("subPath",this.modalTitle)
          formToPost.append("libraryTypeId",this.subCategoryId)
          
          for (let index = 0; index < this.uploadedFiles.length; index++) {
            const element = this.uploadedFiles[index];
            formToPost.append("files",element)
          }
          axios.post(`${this.baseUrl}/Library/Add`, formToPost, {
            headers: { Authorization: `Bearer ${this.user.jwt}`, "Content-Type": "multipart/form-data"}})
          .then((response) => 
          {
            loader.hide();
            this.uploadedFiles=[];
            this.$swal("",response.data.message, "success");
            this.loadData(this.categoryName);
            this.hideModal('modal-lg');
          }).catch((error) => 
          {
            loader.hide();
            var html="";
            if(error.response.data.length>0)
            {
              for (let index = 0; index < error.response.data.length; index++) 
              {
                html +="<small>"+ error.response.data[index].message+"</small><hr>";
              }
            }
            else
            {
              html ="<p class='small'>"+ error.response.data.message+"</p>";
            }
            this.$swal("",html,"error");
          });
        },dropzoneLoadedFile(file) {
          this.uploadedFiles.push(file);
        },checkDupl(file, done) {
          let v = this.$refs.FileDropzone;
          let files = v.getAcceptedFiles()
          let item = files.find( el => el.upload.filename === file.name )
          if (item) {
            done("duplicate")
          } else {
            done()
          }
        },getMyGroups(cat,id){
          this.modalTitle=cat;
          this.subCategoryId=id;
        },fileDelete(fileid,categoryname){
          let loader= this.$loading.show();
          axios.delete(`${this.baseUrl}/Library/Delete/`+fileid, { headers: { Authorization: `Bearer ${this.user.jwt}`},
          }).then((response) => 
          {
            loader.hide();
            this.$swal("",response.data.message, "success");
            if(response.data.results.length>0)
            this.fileArray=  response.data.results.filter(x=>x.category==categoryname)[0].files
            else{
              this.fileArray=[];
              this.$router.go(this.$router.currentRoute)
            }
            
          })
          .catch((error) => 
          {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
          });
        },foldDelete(foldid,categoryname){
          let loader= this.$loading.show();
          axios.delete(`${this.baseUrl}/Library/LibraryType/Delete/`+foldid, { headers: { Authorization: `Bearer ${this.user.jwt}`},
          }).then((response) => 
          {
            loader.hide();
            this.$swal("",response.data.message, "success");
            // if(response.data.results.length>0)
            // this.fileArray=  response.data.results.filter(x=>x.category==categoryname)[0].files
            // else{
            //   this.fileArray=[];
            //   this.$router.go(this.$router.currentRoute)
            // }
            this.loadData(categoryname)
            //this.$router.go(this.$router.currentRoute);
            
          })
          .catch((error) => 
          {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
          });
        },DeleteConfirm(fileid,categoryname,types){
        this.$swal({ 
          title: "WARNING",
          text: "Are you sure? This data will be delete.",
          //type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes",
          cancelButtonText:"No",
          }).then((result) => {
            if (result.value) 
            {
              if(types==1)
              this.fileDelete(fileid,categoryname)
              else if(types==2)
              this.foldDelete(fileid,categoryname)
            }
          });
        },showModal(modalname) {
        this.$bvModal.show(modalname)
        },hideModal(modalid) {
        this.$root.$emit('bv::hide::modal',modalid)
       }
     }
  };
</script>
<style>
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3" v-if="user.rol=='admin'">
        <div class="col-lg-12">
          <a type="button" id="add-modal"  v-b-modal.modal-library  class="btn btn-success" @click="getMyGroups($route.params.name)"><i class="fa fa-folder"></i> + ADD NEW FOLDER</a>
        </div>
    </div>
    <div class="row" id="categories-div">
        <div class="col-md-4" v-for="(item, index) in libraryType" :key="index">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-1 overflow-hidden" style="width:100%">
                            <p class="text-truncate font-size-14 mb-2"><i class="fa fa-folder"></i> {{item.name}}</p>
                            <p v-html="item.explanation"></p>
                        </div>
                    </div>
                </div>
                <div class="card-body border-top py-3">
                    <div class="text-truncate">
                        <router-link :to="{ path: '/libraryfile/'+item.categoeyName+'/'+item.id}" tag="a" v-b-tooltip.hover title="Files" class="btn btn-sm btn-info"><i class="fa fa-file"></i> {{item.library.length}}</router-link>
                        <a type="button" v-b-tooltip.hover title="Add New Files"  v-if="user.rol=='admin' | user.rol=='teacher'"  id="add-modal"  v-b-modal.modal-lg  class="btn btn-sm btn-success float-right ml-1"  @click="getMyGroups(item.name,item.id)"><i class="fa fa-file"></i><i class="fa fa-plus"></i></a>
                        <router-link :to="{ path: '/libraryfile/'+item.categoeyName+'/'+item.id}" tag="a" v-b-tooltip.hover title="Detail" class="btn btn-sm btn-info float-right ml-1"><i class="fa fa-eye"></i></router-link>
                        <a type="button" v-b-tooltip.hover title="Delete" v-if="user.rol=='admin'"  class="btn btn-sm btn-danger float-right" @click="DeleteConfirm(item.id,item.name,2)"><i class="fa fa-trash"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-library" size="lg" :title="'New Folder ' +modalTitle" title-class="font-18" hide-footer>
        <form @submit.prevent="addPostData()" id="add-post-form">
            <div class="form-group">
                <label>Folder Name (*)</label>
                <input type="text" class="form-control" name="name" autocomplete="off"/>
            </div>
            <div class="form-group">
                <label>Explanation</label>
                <ckeditor v-model="editorData" :editor="editor" :config="editorConfig"></ckeditor>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning btn-sm"><i class="fa fa-plus"></i> ADD FOLDER</button>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-lg" size="lg" :title="'New Files '+categoryName+'-'+modalTitle" title-class="font-18" hide-footer>
        <form @submit.prevent="addData()" id="add-file-form">
            <!-- <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>File Name (*)</label>
                        <input type="text" class="form-control" name="name" autocomplete="off"/>
                    </div>
                </div>
            </div> -->
            <div class="row">
              <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                <div class="form-group">
                    <label id="tooltip-link"><i class="fa fa-info-circle"></i>Link</label>
                  <b-tooltip target="tooltip-link" triggers="hover">
                    <img src="@/assets/images/link-example.jpg" style="width:100%" />
                    <p>
                      Copy the place in the browser link section.
                    </p>
                  </b-tooltip>
                    <input type="text" class="form-control" name="libraryLink" placeholder="may be empty" autocomplete="off"/>
                </div>
              </div>
              <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                <div class="form-group">
                  <label id="tooltip-link-video"><i class="fa fa-info-circle"></i> Video Link</label>
                  <b-tooltip target="tooltip-link-video" triggers="hover">
                    <img src="@/assets/images/link-example.jpg" style="width:100%" />
                    <p>
                      Copy the place in the browser link section.
                    </p>
                  </b-tooltip>
                    <input type="text" class="form-control" name="libraryVideoLink" placeholder="may be empty" autocomplete="off"/>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <vue-dropzone ref="FileDropzone" name="files" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-success="dropzoneLoadedFile"></vue-dropzone>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning"><i class="fa fa-check"></i> SAVE FILES</button>
                </div>
            </div>
        </form>
    </b-modal>
  </Layout>
</template>